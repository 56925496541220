<template>
  <div id="dashboard" class="px-4">

    <!-- page header: h1, hard refresh, datepicker --> 
    <div class="d-flex justify-space-between my-3 align-top">
      
      <!-- left-col -->
      <div>
        <h1 class="text-h5 font-weight-bold">Dashboard</h1>
        <v-btn v-if="view === 'reasons'" @click.prevent="hardRefresh(['reasons'])" size="x-small" color="grey">Refresh</v-btn>
        <v-btn v-else-if="view === 'journal'" @click.prevent="hardRefresh(['todaysJournal'])" size="x-small" color="grey">Refresh</v-btn>
        <v-btn v-else-if="view === 'program'" @click.prevent="hardRefresh(['todaysWins', 'oneOffs', 'whatDos'])" size="x-small" color="grey">Refresh</v-btn>
      </div>

      <!-- right-col -->
      <div class="d-flex flex-column" v-if="today && dayOfWeek && todaysDateDisplay">
        <DashDatePicker :today="today" :todayActual="todayActual" :dayOfWeek="dayOfWeek" :todaysDateDisplay="todaysDateDisplay" />
      </div>
    </div>




    <!-- tabs / in-page menu -->
    <v-tabs grow hide-slider>
      <v-tab :class="{ 'active': view === 'reasons' }" @click="setView('reasons')">
        Remember
      </v-tab>
      <v-tab :class="{ 'active': view === 'journal' }" @click="setView('journal')">
        Journal
      </v-tab>
      <v-tab :class="{ 'active': view === 'program' }" @click="setView('program')">
        Program
      </v-tab>
    </v-tabs>



    <!-- display the section -->
    <!-- loading spinner -->
    <div v-if="loading" class="text-center mt-5 pt-5">
      <v-progress-circular
      :size="100"
      color="amber"
      indeterminate
      ></v-progress-circular>
    </div>
    <!-- content, when not loading -->
    <div v-else>
      <section v-if="view === 'reasons'">
        <!-- reasons -->
        <ReasonsDisplay :reasons="reasons" />

        <!-- whatDos -->
        <WhatDos :whatDos="whatDos" />
      </section>
      
      <section v-else-if="view === 'journal'">
        <JournalDisplay :journal="todaysJournal" :uId="uId" :token="token" :today="today" :todayActual="todayActual" />
      </section>
      
      <section v-else-if="view === 'program'">
        <!-- stack wins: my program -->
        <WinsStacker :wins="todaysWins" :program="program" :uId="uId" :token="token" :today="today" :todayActual="todayActual" />
        
        <!-- oneOffs -->
        <OneOffs :oneOffs="oneOffs" :uId="uId" :token="token" :today="today" :todayActual="todayActual" />
      </section>

    </div>


    

  </div>
</template>

<script>
import DashDatePicker from './DashDatePicker.vue';
import ReasonsDisplay from './ReasonsDisplay.vue';
import JournalDisplay from './JournalDisplay.vue';
import WinsStacker from './WinsStacker.vue';
import OneOffs from './OneOffs.vue';
import WhatDos from './WhatDos.vue';

import { isUserLoggedIn } from '@/services/UserServices';
import { getDates } from '@/services/DateServices';
import {
  compareTodaysOneOffsToOneOffActivesThenUpdateIfNeeded,
  compareTodaysWinsToProgramThenUpdateIfNeeded
} from '@/services/DashboardServices';
import { getAllReasonsFromDbase } from '@/services/ReasonsServices';
import { getJournalFromDbase } from '@/services/JournalServices';
import { getAllWinsForTodayFromDbase } from '@/services/WinsServices';
import { getProgramFromDbase } from '@/services/ProgramServices';
import { getAllOneOffsFromDbase, getOneOffActivesFromDbase } from '@/services/OneOffsServices';
// import { getAllOneOffsFromDbase } from '@/services/OneOffsServices';
import { getWhatDosFromDbase } from '@/services/WhatDosServices';


export default {
  components: {
    DashDatePicker,
    ReasonsDisplay,
    JournalDisplay,
    WinsStacker,
    OneOffs,
    WhatDos,
  },
  props: [ 'todaysDate' ],
  data() {
    return {

      loading: true,

      uId: localStorage.getItem('uId'),
      token: localStorage.getItem('idToken'),

      todayActual: null,
      today: null,
      todaysDateDisplay: null,
      dayOfWeek: null,

      view: 'reasons',

      reasons: null,
      todaysJournal: null,
      todaysWins: null,
      program: null,
      oneOffs: null,
      oneOffActives: null,
      whatDos: null,
    };
  },
  watch: {
    async todaysDate(current, old) {
      if (current && current !== old) {
        this.setDates();
        await this.hardRefresh([ 'todaysJournal', 'todaysWins', 'oneOffs', 'oneOffActives' ]);
        await this.checkWinsAgainstProgram();
        await this.checkOneOffsAgainstOneOffActives();
      }
    },
  },
  methods: {

    setView(view) {
      this.view = view;
      this.$cookies.set('lastViewedTab', view);
    },

    setDates() {
      const d = getDates(this.todaysDate);
      this.todayActual = d.todayActual;
      this.today = d.today;
      this.todaysDateDisplay = d.todaysDateDisplay;
      this.dayOfWeek = d.dayOfWeek;
      // console.log('*** this.today ***', this.today);
    },

    async checkWinsAgainstProgram() {
      const updatedWins = await compareTodaysWinsToProgramThenUpdateIfNeeded(this.uId, this.token, this.today, this.todayActual, this.program, this.todaysWins);
      if (updatedWins) {
        await this.hardRefresh(['todaysWins']);
      }
    },

    async checkOneOffsAgainstOneOffActives() {
      console.log('*** checkOneOffsAgainstOneOffActives() gonna send the following data to services file > this.uId, this.today, this.todayActual, this.oneOffs, this.oneOffActives ***', this.uId, this.today, this.todayActual, this.oneOffs, this.oneOffActives);
      const updatedOneOffs = await compareTodaysOneOffsToOneOffActivesThenUpdateIfNeeded(this.uId, this.token, this.today, this.todayActual, this.oneOffs, this.oneOffActives);
      if (updatedOneOffs) {
        await this.hardRefresh(['oneOffs', 'oneOffActives']);
      }
    },

    async hardRefresh(listOfThingsToUpdate) {
      // console.log('running hardRefresh()');
      this.loading = true;

      // reasons, todaysJournal, todaysWins, program, oneOffs, whatDos
      if (listOfThingsToUpdate.includes('reasons')) {
        this.reasons = await getAllReasonsFromDbase(this.uId, this.token);
      }
      if (listOfThingsToUpdate.includes('todaysJournal')) {
        this.todaysJournal = await getJournalFromDbase(this.uId, this.token, this.today);
      }
      if (listOfThingsToUpdate.includes('todaysWins')) {
        this.todaysWins = await getAllWinsForTodayFromDbase(this.uId, this.token, this.today);
      }
      if (listOfThingsToUpdate.includes('program')) {
        this.program = await getProgramFromDbase(this.uId, this.token);
      }
      if (listOfThingsToUpdate.includes('oneOffs')) {
        this.oneOffs = await getAllOneOffsFromDbase(this.uId, this.token, this.today);
      }
      if (listOfThingsToUpdate.includes('oneOffActives')) {
        this.oneOffActives = await getOneOffActivesFromDbase(this.uId, this.token);
      }
      if (listOfThingsToUpdate.includes('whatDos')) {
        this.whatDos = await getWhatDosFromDbase(this.uId, this.token);
      }

      this.loading = false;
    },

  },
  async created() {
    console.clear();
    console.log('***** dashboard created *****', this.todaysDate);
    // todo - need to fix the loop that checks oneOffs against oneOffActives - bc there's a difference, but it's nto loading.
    
    // if user auth has expired, then send to homepage to log in again
    if (!isUserLoggedIn()) {
      this.$router.push('/');
      return;
    }

    // set the last viewed tab (if any)
    if (this.$cookies.isKey('lastViewedTab')) {
      let lvt = this.$cookies.get('lastViewedTab');
      if (lvt === 'reasons' || lvt === 'journal' || lvt === 'program') {
        this.setView(lvt);
      } else {
        this.setView('reasons');
      }
    }

    // set dates 
    this.setDates();

    // get all data
    await this.hardRefresh(['reasons', 'todaysJournal', 'todaysWins', 'program', 'oneOffs', 'oneOffActives', 'whatDos']);

    // check wins against program
    await this.checkWinsAgainstProgram();

    // check oneOffs against oneOffActives
    await this.checkOneOffsAgainstOneOffActives();

  },
};
</script>


<style lang="scss" scoped>
button.v-tab {
  border: 2px solid black;
  border-radius: 20px;
}
button.v-tab.active {
  background-color: black;
  color: gold;
  font-weight: bold;
  text-decoration: underline;
}
</style>
