<template>
  <div>
  <!-- <div class="pa-3 text-white bg-black"> -->
    <!-- <p class="text-subtitle-2">Add a one-off win that you can stack today (non-recurring)</p> -->


    <v-card
      density="compact"
      class="mt-3 mb-5 bg-grey">
      <v-card-text>
        <v-form @submit.prevent="addAOneOff" density="compact">
          <v-textarea 
            clearable
            auto-grow
            density="compact"
            bg-color="white"
            rows="2"
            placeholder="Add a one-off win that you can stack today (non-recurring)"
            v-model="newOneOff"></v-textarea>
          <v-btn class="bg-green" block type="submit" :disabled="invalidOneOff"><span class="text-white">Add this One-Off</span></v-btn>
        </v-form>
      </v-card-text>
    </v-card>


  </div>
</template>




<script>
import { addNewOneOffToToday } from '@/services/OneOffsServices';


export default {
  props: [ 'uId', 'token', 'today', 'todayActual' ],
  data() {
    return {
      newOneOff: '',
    };
  },
  computed: {
    invalidOneOff() {
      return this.newOneOff.trim().length > 0 ? false : true;
    },
  },
  methods: {
    async addAOneOff() {
      console.log('addAOneOff()', this.newOneOff);
      const created = await addNewOneOffToToday(this.uId, this.token, this.today, this.todayActual, this.newOneOff.trim());
      this.newOneOff = "";
      if (created) {
        console.log('oneOff created successfully');
      }
    },
  },
};
</script>


