/** JOURNAL SERVICES */
/** JOURNAL SERVICES */
/** JOURNAL SERVICES */

// DEPRECATED - CREATE A NEW JOURNAL ENTRY - returns true if create is successful, or throws error.
export async function createJournal(uId, token, today, todayActual, journal) {
  // console.clear();
  // console.log('*** starting createJournal(uId, token, today, todayActual, journal) ***', uId, token, todayActual, journal);
  // build the data
  const newJournal = {
    journal,
    dateCreated: todayActual,
    dateUpdated: todayActual,
  };
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}.json?auth=${token}`, {
    method: 'PATCH',
    body: JSON.stringify({'journal': newJournal}),
  });
  const responseData = await response.json();
  // "err handling"
  if (!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to create new Journal');
  } else {
    return responseData;
  }
}

// READ ONE JOURNAL ENTRY - returns an object, or null.
export async function getJournalFromDbase(uId, token, today) {
  // console.log('*** starting getJournalFromDbase(uId, token, today) ***', uId, token, today);
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/journal.json?auth=${token}`);
  // error handling
  if (!response.ok) {
    throw new Error(response.message || 'Failed to get today\'s Journal entry');
  } 
  const data = await response.json();
  
  let j = null;
  if (data && data.journal) {
    j = data;
  }
  return j;
}


// UPDATE A JOURNAL ENTRY - returns true if update is successful, or throws error.
export async function updateJournal(uId, token, today, journal) {
  // console.log('*** starting updateJournal(uId, token, today, journal) ***', uId, token, today, journal);
  const updatedJournal = {
    journal: journal.journal,
    dateCreated: journal.dateCreated,
    dateUpdated: journal.dateUpdated,
  }
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}.json?auth=${token}`, {
    method: 'PATCH',
    body: JSON.stringify({'journal': updatedJournal}),
  });
  // get the response
  const responseData = await response.json();
  if(!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to update a Journal');
  } else {
    return responseData;
  }
}


// DELETE A JOURNAL ENTRY - tbd
