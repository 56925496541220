/** DASHBOARD SERVICES */
/** DASHBOARD SERVICES */
/** DASHBOARD SERVICES */

import { addAWinToToday } from '@/services/WinsServices';
import {addNewOneOffToToday} from "@/services/OneOffsServices";
// import { getOneOffActivesFromDbase } from "@/services/OneOffsServices";

export async function compareTodaysWinsToProgramThenUpdateIfNeeded(uId, token, today, todayActual, program, wins) {
  // console.log('*** starting compareTodaysWinsToProgramThenUpdateIfNeeded(uId, token, today, todayActual, program, wins) ***', uId, token, today, todayActual, program, wins);


  // look through wins & find their programId's
  let programIdsOfTodaysWins = [];
  if (wins && wins.length > 0) {
    for (const key in wins) {
      programIdsOfTodaysWins.push(wins[key].programId);
    }
  }
  
  // look through program: find missing wins & create newWin for each; get a list of wins to add to today
  let winsToAddToToday = [];
  if (program && program.length > 0) {
    for (const key in program) {
      if (!programIdsOfTodaysWins.includes(program[key].id)) {
        const newWin = {
          programId: program[key].id,
          win: program[key].win,
          stacked: false,
          dateCreated: today,
          dateUpdated: today,
        };
        // addAWinToToday(uId, today, newWin, token);
        winsToAddToToday.push(newWin);
      }
    }
  }


  // if necessary, make new API calls and mutate store
  if (winsToAddToToday.length > 0) {
    for (const key in winsToAddToToday) {
      const win = winsToAddToToday[key];
      await addAWinToToday(uId, token, today, todayActual, win);
    }
    return true;
  } else {
    return false;
  }
}


export async function compareTodaysOneOffsToOneOffActivesThenUpdateIfNeeded(uId, token, today, todayActual, todayOneOffs, oneOffActives){
  console.log('*** starting compareTodaysOneOffsToOneOffActivesThenUpdateIfNeeded(uId, token, today, todayActual, todayOneOffs, oneOffActives) ***', uId);
  console.log('*** today, todayActual ***', today, todayActual);
  console.log('*** todayOneOffs ***', todayOneOffs);
  console.log('*** oneOffActives ***', oneOffActives);


  // look through oneOffs & find their oneOffId's
  let oneOffIdsOfTodayOneOffs = [];
  if (todayOneOffs && todayOneOffs.length > 0) {
    for (const key in todayOneOffs) {
      console.log('*** for key in todayOneOffs ***', key, todayOneOffs[key]);
      oneOffIdsOfTodayOneOffs.push(todayOneOffs[key].oneOffId);
    }
  }
  console.log('*** idsOfTodayOneOffs ***', oneOffIdsOfTodayOneOffs);

  // if there are oneOffActives
  if (oneOffActives && oneOffActives.length > 0) {
    let oneOffsToAddToToday = [];


    for (const key in oneOffActives) {
      if (!oneOffIdsOfTodayOneOffs.includes(oneOffActives[key].id)) {

        const newOneOff = {
          oneOffId: oneOffActives[key].id,
          oneOff: oneOffActives[key].oneOff,
          stacked: false,
          dateCreated: todayActual,
          dateUpdated: todayActual,
        };
        oneOffsToAddToToday.push(newOneOff);
      }
    }

    if (oneOffsToAddToToday.length > 0) {
      console.log('*** there are oneOffs to add to today ***', oneOffsToAddToToday);
      for (const key in oneOffsToAddToToday) {
        console.log('*** oneOffsToAddToToday[key] ***', key, oneOffsToAddToToday[key]);
        await addNewOneOffToToday(uId, token, today, todayActual, oneOffsToAddToToday[key]);
      }
    }
  }

  return true;
}
