/** WINS SERVICES */
/** WINS SERVICES */
/** WINS SERVICES */


// CREATE A WIN - return true if create is successful, or throws error.
export async function addAWinToToday(uId, token, today, todayActual, win) {
  // console.log('*** starting addAWinToToday(uId, token, today, todayActual, win) ***', uId, token, today, todayActual, win);
  // build data
  const w = {
    win: win.win,
    stacked: false,
    programId: win.programId,
    dateCreated: todayActual,
    dateUpdated: todayActual
  };
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/wins.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(w),
  });
  // response
  // const responseData = await response.json();
  if (!response.ok) {
    // console.log(responseData);
    throw new Error(response.message || 'Failed to add a win to today');
  } else {
    return true;
  }
}



// READ ALL WINS - return array of wins, or empty array.
export async function getAllWinsForTodayFromDbase(uId, token, today) {
  // console.log('*** starting getAllWinsForTodayFromDbase(uId, token, today) ***', uId, token, today);
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/wins.json?auth=${token}`);
  const responseData = await response.json();
  // err handling
  if(!response.ok) {
    // console.log(responseData);
    throw new Error(response.message || 'Failed to get today\'s wins from dbase');
  }
  const wins = [];
  for (const key in responseData) {
    const winObj = {
      id: key,
      win: responseData[key].win,
      stacked: responseData[key].stacked,
      programId: responseData[key].programId,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    wins.push(winObj);
  }
  // console.log('got todaysWins', wins);
  return wins;
}



// UPDATE A WIN - return true if update is successful, or error.
export async function updateAWin(uId, token, today, todayActual, win, stackedStatus) {
  // console.log('*** starting updateAWin(uId, token, today, todayActual, win, stackedStatus) ***', uId, token, today, todayActual, win, stackedStatus);
  const winId = win.id;
  const updatedWin = {
    win: win.win,
    stacked: stackedStatus,
    programId: win.programId,
    dateCreated: win.dateCreated,
    dateUpdated: todayActual,
  };
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/wins/${winId}.json?auth=${token}`, {
    method: 'PATCH',
    body: JSON.stringify(updatedWin)
  });
  // const responseData = await response.json();
  if (!response.ok) {
    // console.log('something went wrong', responseData);
    throw new Error(response.message || 'Failed to update a Win');
  } else {
    return true;
  }
}

// DELETE A WIN - return true if delete is successful, or error.
export async function deleteAWin(uId, token, today, winId) {
  // console.log('*** starting deleteAWin(uId, token, today, winId) ***', uId, token, today, winId);

  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/wins/${winId}.json?auth=${token}`, {
    method: 'DELETE',
  });
  // response
  const responseData = await response.json();
  // console.log('responseData', responseData);
  // console.log('response.ok', response.ok);
  if (!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to delete a Win');
  } else {
    return responseData;
  }
}
