<template>
  <div class="pb-5 mb-5">

    <div v-if="todaysWins && todaysWins.length">
      <p class="text-body-2 my-3">
        Your <strong>Daily Program</strong> is how you live each day. According to your own definitions, what do you have to do every day to earn your own respect? If you do them, then that is a win. Consistently and persistently love yourself, by <strong><em>stacking</em></strong> these wins daily — stack em high.
      </p>

      <v-card v-for="win in todaysWins"
        :key="win.id"
        :id="win.id"
        class="d-flex pa-4 my-4"
      >
        <v-icon
          v-if="!!win.stacked"
          class="mr-2"
          @click.prevent="stackWin(win, false)"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
          v-else
          class="mr-2"
          @click.prevent="stackWin(win, true)"
        >
          mdi-checkbox-blank-outline
        </v-icon>
        <div class="w-100">
          <p :class="win.stacked ? 'text-decoration-line-through' : ''">{{ win.win }}</p>
          <p v-if="pIds && !pIds.includes(win.programId)" class="text-right text-red text-caption mb-0 pb-0" @click.prevent="deleteWin(win.id)">delete</p>
        </div>
      </v-card>
    </div>

    <!-- if user does not have any wins -->
    <div v-else id="no-wins-in-program" class="pt-5">
      <p class="font-weight-bold">you have yet to set your program.</p>
      <p>your daily program is how you live each day. how consistently and persistently you stick to your to-do list. </p>
      <p>these activities are things that make YOU feel proud, make YOU feel like your true self.</p>
      <p>each activity you complete, counts as a "win". you stack your wins high every single day, day after day, and you WILL startto feel better about yourself and about your life.</p>
      <p>some suggested/fundamental <em>daily</em> acts of self-love and self-investment include:</p>
      <ul class="ml-5 pb-5">
        <li>eating right (and keeping a food journal)</li>
        <li>exercising</li>
        <li>talking/spending time with loved ones</li>
        <li>or learning/using a new skill</li>
        <li>hobbies or acts of charity are good, to</li>
        <li>whatever else that makes you love yourself</li>
      </ul>
      <router-link to="/my-account" class="pt-5 font-weight-bold">add wins to your program ></router-link>
    </div>

  </div>
</template>

<script>
import { updateAWin, getAllWinsForTodayFromDbase, deleteAWin } from '@/services/WinsServices';

export default {
  props: [ 'wins', 'program', 'uId', 'token', 'today', 'todayActual' ],
  data() {
    return {
      todaysWins: [],
      programIds: null,
    };
  },
  computed: {
    pIds() {
      let ids = [];
      for (let p in this.program) {
        ids.push(this.program[p].id);      
      }
      return ids;
    },
  },
  methods: {
    async stackWin(win, stackedStatus) {
      const didItUpdate = await updateAWin(this.uId, this.token, this.today, this.todayActual, win, stackedStatus);
      if (didItUpdate) {
        // update front end
        // console.log('winsstacker > update was successful', this.todaysWins);
        const tw = await getAllWinsForTodayFromDbase(this.uId, this.token, this.today);
        this.$cookies.set('todaysWins', this.todaysWins);
        console.log('winsstacker > update was successful', tw);
        this.todaysWins = tw;
        // this.todaysWins = await getAllWinsForTodayFromDbase(this.uId, this.token, this.today);
      }
    },
    async deleteWin(wId) {
      console.clear();
      console.log('starting deleteWin()', wId);
      const deleteSuccessful = await deleteAWin(this.uId, this.token, this.today, wId);
      console.log('deleteSuccessful', deleteSuccessful);
      // update front end
      this.todaysWins = await getAllWinsForTodayFromDbase(this.uId, this.token, this.today);
      this.$cookies.set('todaysWins', this.todaysWins);
    },
  },
  created() {
    this.todaysWins = [...this.wins];
  },
};
</script>

<style lang="scss" scoped>
i:hover {
  cursor: pointer;
}
#no-wins-in-program {
  p {
    padding-bottom: 12px;
  }
  a {
    color: black;
    text-decoration: none;
  }
}
</style>
