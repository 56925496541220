/** ONE-OFF's SERVICES */
/** ONE-OFF's SERVICES */
/** ONE-OFF's SERVICES */

// HELPERS

// CREATE/ADD A ONE-OFF-ACTIVE
export async function addOneOffActiveToUser(uId, token, oneOffObj) {
  // console.log('*** starting addOneOffActiveToUser(uId, token, oneOffObj) ***', uId, token, oneOffObj);

  let apiUrl;
  if (oneOffObj.oneOffId) {
    apiUrl = `https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/oneoffs/${oneOffObj.oneOffId}.json?auth=${token}`;
  }
  else {
    apiUrl = `https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/oneoffs.json?auth=${token}`;
  }
  const response = await fetch(apiUrl, {
    method: 'POST',
    body: JSON.stringify(oneOffObj)
  });

  const responseData = await response.json();
  if (!response.ok) {
    // console.log(responseData);
    throw new Error(responseData || 'Failed to add oneOffActive to User');
  }
  return responseData.name;
}

// READ ALL ONE-OFF-ACTIVES (from user)
export async function getOneOffActivesFromDbase(uId, token) {
  console.log('*** starting getOneOffActivesFromDbase(uId, token) ***', uId);
  console.log(token);
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/oneoffs.json?auth=${token}`);

  const responseData = await response.json();
  if (!response.ok) {
    console.log('*** something went wrong ***', responseData);
    throw new Error(responseData || '*** Failed to get oneOffActivesFromDbase ***');
  }
  let allOneOffActives = [];
  console.log('*** this is the oneOffActives ***', responseData);
  for (const key in responseData) {
    allOneOffActives.push({
      id: key,
      oneOff: responseData[key].oneOff,
      stacked: responseData[key].stacked,
      datedCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    });
  }
  console.log('*** getAllOneOffActives ***', allOneOffActives);
  return allOneOffActives;
}

// UPDATE ONE-OFF-ACTIVES
// todo - write the fucntion that updates/patches a oneOffActive
// DELETE REMOVE A ONE-OFF-ACTIVE FROM THE USER
export async function removeOneOffActiveFromUser(uId, token, oneOffId) {
  console.log('*** starting removeOneOffActiveFromUser(uId, token, oneOffId) ***', uId, token, oneOffId);

  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/oneoffs/${oneOffId}.json?auth=${token}`, {
    method: 'DELETE',
  });
  // response
  const responseData = await response.json();
  if (!response.ok) {
    // console.log(responseData);
    throw new Error(responseData || 'Failed to delete a OneOffAtive');
  }
  else {
    console.log('*** success deleting oneOffActive ***', responseData);
    return responseData;
  }
}



// CREATE A ONE-OFF
export async function addNewOneOffToToday(uId, token, today, todayActual, oneOff) {
  // console.log('*** starting addNewOneoffToToday(uId, token, today, todayActual, oneOff) ***', uId, token, today, todayActual, oneOff);

  // step 1: sanitize the newOneOff
  // step 2: different prop values depending on whether the oneOffActive already exists
  // step 3:


  // step 1
  let newOneOff = {
    dateCreated: todayActual,
    dateUpdated: todayActual,
  };


  // step 2: if this oneOffActives already exists
  if (oneOff.oneOffId) {
    newOneOff.oneOff = oneOff.oneOff;
    newOneOff.oneOffId = oneOff.oneOffId;
    newOneOff.stacked = oneOff.stacked;
  }
  // step 2: else this is a new OneOff; create a new oneOffActive
  else {
    newOneOff.oneOff = oneOff.oneOff || oneOff;
    newOneOff.stacked = false;
    newOneOff.oneOffId = await addOneOffActiveToUser(uId, token, newOneOff);
  }


  // update today's oneOff
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/oneoffs.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(newOneOff)
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(responseData || 'Failed to add a new oneOff');
  }

  // await getAllOneOffsFromDbase(uId, token, today);

}

// READ ALL ONE-OFF's (from today) - return array of oneOffs, or empty array.
export async function getAllOneOffsFromDbase(uId, token, today) {
  // console.log('*** starting getOneOffsFromDbase() ***', uId, token, today);
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/oneoffs.json?auth=${token}`);
  // response
  const responseData = await response.json();
  if (!response.ok) {
    // console.log(responseData);
    throw new Error(response.message || 'Failed to get all oneOff\'s for today');
  }
  // return data
  let oneOffs = [];
  if (responseData) {
    for (const key in responseData) {
      const oneOff = responseData[key];
      const oneOffObj = {
        id: key,
        oneOffId: oneOff.oneOffId,
        oneOff: oneOff.oneOff,
        stacked: oneOff.stacked,
        dateCreated: oneOff.dateCreated,
        dateUpdated: oneOff.dateUpdated,
      };
      oneOffs.push(oneOffObj);
    }
  }
  console.log('*** getAllOneOffs ***', oneOffs);
  return oneOffs;
}

// UPDATE A ONE-OFF - this [un]stacks a oneOff
export async function updateAOneOff(uId, token, today, todayActual, oneOff) {
  console.log('*** starting updateAOneOff(uId, token, today, todayActual, oneOff) ***', uId, token, today, todayActual, oneOff);

  const oId = oneOff.oneOffId;
  const id = oneOff.id;
  let updatedOneOff = {
    oneOffId: oId,
    oneOff: oneOff.oneOff,
    stacked: oneOff.stacked,
    dateCreated: oneOff.dateCreated,
    dateUpdated: todayActual,
  };

  // step 1: delete the oneOff
  // step 2: stacked or unstacked?
  // step 3: re-add the updatedOneOff

  await deleteAOneOff(uId, token, today, updatedOneOff, id);

  if (!oneOff.stacked) {
    updatedOneOff.oneOffId = null;
  }

  await addNewOneOffToToday(uId, token, today, todayActual, updatedOneOff);

}

// DELETE A ONE-OFF - return true if delete is successful, or error.
export async function deleteAOneOff(uId, token, today, oneOff, id) {
  // console.log('*** starting deleteOneProgram(uId, token, today, oId) ***', uId, token, today, oId);

  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/days/${uId}/${today}/oneoffs/${id}.json?auth=${token}`, {
    method: 'DELETE',
  });

  const responseData = await response.json();
  if (!response.ok) { 
    // console.log(responseData);
    throw new Error(responseData || 'Failed to delete a OneOff');
  }

  // delete the oneOffActive
  await removeOneOffActiveFromUser(uId, token, oneOff.oneOffId);
  return responseData;
}

