/** REASONS SERVICES */

// CREATE A REASON - returns true if create is successful, or throws error.
export async function addNewReason(uId, token, today, reason) {
  // console.log('*** starting addNewReason(uId, token, today, reason) ***', uId, token, today, reason);
  // build the data 
  const newReason = {
    reason,
    dateCreated: today,
    dateUpdated: today,
  };
  // REST API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(newReason)
  });
  const responseData = await response.json();
  // "err handling"
  if (!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to add a new Reason');
  } else {
    return responseData;
  }
}


// READ ALL REASONS - returns an array of reasons, or empty array.
export async function getAllReasonsFromDbase(uId, token) {
  // console.log('*** starting getAllReasonsFromDbase(uId, token) ***', uId);
  // console.log('*** starting getAllReasonsFromDbase(uId, token) ***', token);
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons.json?auth=${token}`);
  // error handling
  if(!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to get all Reasons');
  }
  // manipulate & return data
  const responseData = await response.json();
  let reasons = [];
  for (const key in responseData) {
    const reasonObj = {
      id: key,
      reason: responseData[key].reason,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    reasons.push(reasonObj);
  }
  // console.log('*** reasons ***', reasons);
  return reasons;
}


// READ ONE REASON - returns one reason object, or false.
export async function getOneReasonFromDbase(uId, token, rId) {
  // console.log('*** starting getOneReasonFromDbase(uId, token, rId) ***', uId, token, rId);
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons/${rId}.json?auth=${token}`);
  // error handling
  if(!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to get one Reason');
  }
  // manipulate & return data
  const r = await response.json();

  if (r) {
    return {
      reason: r.reason,
      dateCreated: r.dateCreated,
      dateUpdated: r.dateUpdated,
    };
  } else {
    return false;
  }
}


// UPDATE A REASON - return true update is successful, or throws error
export async function updateReason(uId, token, todayActual, rId, reason) {
  // console.log('*** starting updateReason(uId, token, todayActual, rId, reason ***)', uId, token, todayActual, rId, reason);
  const updatedReason = {
    reason,
    dateUpdated: todayActual,
  }
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons/${rId}.json?auth=${token}`, {
    method: 'PATCH',
    body: JSON.stringify(updatedReason)
  });
  // get the response
  const responseData = await response.json();
  if(!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to update a Reason');
  } else {
    return responseData;
  }
}


// DELETE A REASON - returns true if delete is successful, or error
export async function deleteReason(uId, token, reasonId) {
  // console.log('*** starting deleteReason(uId, token, reasonId) ***', uId, token, reasonId);
  // API call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/users/${uId}/reasons/${reasonId}.json?auth=${token}`, {
    method: 'DELETE',
  });
  // response
  const responseData = await response.json();
  if (!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to delete a Reason');
  } else {
    return responseData;
  }
}


