/** USER SERVICES */
const FIREBASE_KEY = process.env.VUE_APP_FIREBASE_API_KEY;

function helperSetUserInLocalStorage(uId, idToken, refreshToken, expiresIn) {
  // console.log('*** helperSetUserInLocalStorage(uId, idToken, refreshToken, expiresIn) > setting user in local storage ***', uId, idToken, refreshToken, expiresIn);
  localStorage.setItem('uId', uId);
  localStorage.setItem('idToken', idToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('tokenExpiry', (Date.now() + expiresIn * 1000).toString());
}
function helperClearUserFromLocalStorage() {
  // console.log('*** helperClearUserFromLocalStorage() > clearing the user from localStorage ***');
  localStorage.removeItem('uId');
  localStorage.removeItem('idToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpiry');
}


export async function submitLogin(email, pass) {
  // console.log('starting UserServices > submitLogin', email, pass);

  // try to log in
  // eslint-disable-next-line no-useless-catch
  try {
    // (call the auth server)
    const response = await fetch(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${FIREBASE_KEY}`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password: pass,
        returnSecureToken: true,
      }),
    });
    const responseData = await response.json();
    // console.log('*** submitLogin() > responseData ***', responseData);

    if (response.ok) {
      const uId = responseData.localId;
      const idToken = responseData.idToken;
      const refreshToken = responseData.refreshToken;
      const expiresIn = parseInt(responseData.expiresIn);

      // set user info
      helperSetUserInLocalStorage(uId, idToken, refreshToken, expiresIn);

      return responseData;
    }
  } catch (err) {
    // console.log('*** Error in submitLogin() ***', err);
    throw err;
  }
}

export async function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');

  const response = await fetch(`https://securetoken.googleapis.com/v1/token?key=${FIREBASE_KEY}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  });
  const data = await response.json();
  const expiresIn = parseInt(data['expires_in']);
  // console.log('*** refreshToken() > data ***', data);

  // set user info
  helperSetUserInLocalStorage(data.uId, data.idToken, data.refreshToken, expiresIn);
}

export async function getAuthToken() {
  const tokenExpiry = localStorage.getItem('tokenExpiry');

  // Refresh token if it will expire within the next 5 minutes
  if (Date.now() > tokenExpiry - 5 * 60 * 1000) {
    await refreshToken();
  }

  return localStorage.getItem('idToken');
}

export function isUserLoggedIn() {
  const tokenExpiry = localStorage.getItem('tokenExpiry');
  const uId = localStorage.getItem('uId');
  const token = localStorage.getItem('idToken');
  // console.log('*** isUserLoggedIn() > tokenExpiry ***', tokenExpiry);
  // console.log('*** isUserLoggedIn() > uId ***', uId);
  // console.log('*** isUserLoggedIn() > token ***', token);

  // user is "logged in" if tokenExpiry is still valid for 10+ min
  const userIsLoggedIn = Date.now() <= tokenExpiry - 10 * 60 * 1000;

  // if not logged in, then clear localStorage
  if (!tokenExpiry || !uId || !token || !userIsLoggedIn) {
    helperClearUserFromLocalStorage();
    return false;
  }
  return true;
}

export async function signTheUserOut() {
  helperClearUserFromLocalStorage();
}


// https://firebase.google.com/docs/reference/rest/auth#section-sign-in-email-password
// Sign in anonymously
// You can sign in a user anonymously by issuing an HTTP POST request to the Auth signupNewUser endpoint.
// Method: POST
// https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=[API_KEY]






/**
 * used in /dash/DashBoard
 * TBD: used in /account/...
 */
export async function getAllReasonsFromDbase(uId, token) {
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/reasons/${uId}.json?auth=${token}`);
  const responseData = await response.json();
  
  // error handling
  if(!response.ok) { 
    // console.log(responseData);
    const error = new Error(response.message || 'Failed to add a win to today');
    throw error;
  }

  // manipulate & return data
  const reasons = [];
  for (const key in responseData) {
    const reasonObj = {
      id: key,
      reason: responseData[key].reason,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    reasons.push(reasonObj);
  }
  return reasons;
}



// this needs work
// this needs work
// this needs work
// this needs work
// this needs work
// this needs work
// this needs work
// this needs work
export async function getJournalForTodayFromDbase(uId, token, today) {
  const journal = 'lol journal for today from dbase';
  // console.log('*** getJournalForTodayFromDbase(uId, token, today) ***', uId, token, today);
  return journal, uId, token, today;
}
export async function getAllWinsForTodayFromDbase(uId, token, today) {
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/wins/${uId}/${today}.json?auth=${token}`);
  const responseData = await response.json();
  // err handling
  if (!response.ok) {
    // console.log(responseData);
    throw new Error(response.message || 'Failed to get today\'s wins from dbase');
  }
  const wins = [];
  for (const key in responseData) {
    const winObj = {
      id: key,
      win: responseData[key].win,
      stacked: responseData[key].stacked,
      programId: responseData[key].programId,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    wins.push(winObj);
  }
  return wins;
}
export async function getProgramFromDbase(uId, token) {
  // console.log('starting getProgramFromDbase()');
  // API Call
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/program/${uId}.json?auth=${token}`);
  const responseData = await response.json();
  // "err handling" - needs to be made real
  if(!response.ok) { 
    // console.log(responseData);
    throw new Error(response.message || 'Failed to add a win to today');
  }
  // manipulate & return data
  let program = [];
  for (const key in responseData) {
    const winObj = {
      id: key,
      win: responseData[key].win,
      dateCreated: responseData[key].dateCreated,
      dateUpdated: responseData[key].dateUpdated,
    };
    program.push(winObj);
  }
  // store.dispatch('wins/setProgram', {program});
  // console.log('program', program);
  return program;
}
async function addAWinToTodaysWins(uId, token, today, win) {

  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/wins/${uId}/${today}.json?auth=${token}`, {
    method: 'POST',
    body: JSON.stringify(win),
  });
  // const responseData = await response.json();
  
  if (!response.ok) {
    // console.log(responseData);
    throw new Error(response.message || 'Failed to add a win to today');
  }
  
}
export async function compareTodaysWinsToProgramThenUpdateIfNeeded( uId, token, today, program, wins ) { 
  // console.log('starting compareTodaysWinsToProgramThenUpdateIfNeeded()');
  let programIdsOfTodaysWins = [];
  let winsToAddToToday = [];
  // look through wins & find their programId's
  if (wins && wins.length > 0) {
    for (const key in wins) {
      programIdsOfTodaysWins.push(wins[key].programId);
    }
  }
  // console.log('got programIdsOfTodaysWins:', programIdsOfTodaysWins);
  // look through program: find missing wins & create newWins
  if (program && program.length > 0) {
    for (const key in program) {
      if (!programIdsOfTodaysWins.includes(program[key].id)) {
        const newWin = {
          programId: program[key].id,
          win: program[key].win,
          stacked: false,
          dateCreated: today,
          dateUpdated: today,
        };
        // addAWinToToday(uId, today, newWin, token);
        winsToAddToToday.push(newWin);
      }
    }
  }
  // if necessary, make new API calls and mutate store
  if (winsToAddToToday.length > 0) {
    for (const key in winsToAddToToday) {
      const win = winsToAddToToday[key];
      await addAWinToTodaysWins(uId, token, today, win);
    }
    // console.log('winsToAddToToday:', winsToAddToToday);
    // wins = await getAllWinsForTodayFromDbase(uId, today, token);
    return true;
  } else {
    return false;
  }
}
// UPDATE (ONE WIN)
// export async function updateOneWin(uId, token, today, win) {
export async function updateOneWin(uId, token, today, win, stackedStatus) {
  // uId, today, 
  // const dateOfWin = data.dateOfWin;
  const winId = win.id;
  const updatedWin = {
    win: win.win,
    stacked: stackedStatus,
    programId: win.programId,
    dateCreated: win.dateCreated,
    dateUpdated: today,
  };
  const response = await fetch(`https://stackin-vue-default-rtdb.firebaseio.com/wins/${uId}/${today}/${winId}.json?auth=${token}`, {
    method: 'PUT',
    body: JSON.stringify(updatedWin)
  });
  // const responseData = await response.json();
  if (!response.ok) {
    // console.log(responseData);
    throw new Error(response.message || 'Failed to update a Win');
  } else {
    return true;
  }


  // mutate the store
  // let wins = await getAllWinsForTodayFromDbase(uId, today, token);
  // context.commit('setWins', wins);
}

