<template>
  <div>
    <div class="d-flex justify-space-between align-baseline">
      <v-btn @click.prevent="goToYesterday" size="x-small" variant="flat" class="text-h6 font-weight-bold">&lt;</v-btn>
      <p class="text-h6 font-weight-bold">{{ dayOfWeek }}, {{ todaysDateDisplay }}</p>
      <v-btn @click.prevent="goToTomorrow" size="x-small" variant="flat" class="text-h6 font-weight-bold">></v-btn>
    </div>
    <div v-if="this.today && this.todayActual && this.today != this.todayActual" class="text-right">
      <v-btn size="x-small" @click="goToToday">go to today</v-btn>
    </div>
  </div>
</template>


<script>
import { getYMDYesterday, getYMDTomorrow } from '@/services/DateServices';
export default {
  props: [ 'today', 'todayActual', 'dayOfWeek', 'todaysDateDisplay' ],
  methods: {
    async goToYesterday() {
      if (this.today) {
        const yesterdayDate = await getYMDYesterday(this.today);
        // console.log('goToYesterday', yesterdayDate);
        this.$router.push(`/dash/${yesterdayDate}`);
      }
      
    },
    async goToTomorrow() {
      const tomorrowDate = await getYMDTomorrow(this.today);
      // console.log('goToTomorrow', tomorrowDate);
      this.$router.push(`/dash/${tomorrowDate}`);
    },
    goToToday() {
      console.clear();
      console.log('starting goToToday()', this.todayActual);
      this.$router.push(`/dash/${this.todayActual}`);
    },
  },
};
</script>
